.gpm {
    td {
        border: 0;
    }

    .gpm-search {
        position: relative;
        padding: 0 3rem 0.5rem $padding-default;
        text-indent: 2.5rem;
        &:after {
            position: absolute;
            right: 60px;
            top: 10px;
            content: '\f002';
            font-family: 'FontAwesome';
        }

        @include breakpoint(mobile-only) {
            padding-left: 1rem;

        }
    }

    .sort-actions {
        float: right;
        margin: 0 24px;

        @include breakpoint(mobile-only) {
            float: none;
        }

        > * {
            font-size: 1rem;
            display: inline-block;
            vertical-align: middle;
        }

        .sort-icon {
            cursor: pointer;
        }

        select {
            margin: 0;
            display: none;
        }

        .selectize-input {
            width: 9rem;
            vertical-align: middle;
            line-height: 21px;

            input {
                line-height: 21px;
            }
        }
    }

    .gpm-name {
        white-space: nowrap;

        @include breakpoint(mobile-only) {
            white-space: normal;
        }
    }

    .gpm-version {
        padding-left:0.5rem;
        font-size: 0.9rem;
    }

    .gpm-testing {
        @extend .badge;
        border-radius: 3px;
        padding: 0 4px;
        font-size: 0.75rem;
    }

    .theme .gpm-testing {
        line-height: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 0 0 3px;
        line-height: 1.3;
    }

    .gpm-actions {

        .enabled, .disabled {
            font-size: 1.6rem;
        }
    }

    .gpm-item-info {
        position: relative;
        padding-bottom: 1rem;
        margin-bottom: 3rem;
        overflow: hidden;



        .gpm-item-icon {
            position: absolute;
            right: $padding-default;
            font-size: 20rem;
        }
        table {
            position: relative;
        }
        td {
            border: 0;
            text-align: left !important;

            &.double {
                padding-left: 2rem;
            }

            &:first-child {
                white-space: nowrap;
            }

            @include breakpoint(mobile-only) {
                word-wrap: break-word;
                word-break: break-word;
                @include hyphens(auto);
            }
        }
        tr:hover {
            background: inherit;
        }


    }

    .badge.update {
        display: inline-block;
        border-radius: 4px;
        padding: 2px 10px;
        margin-left: 1rem;
    }

    .gpm-ribbon {
        overflow: hidden;
        white-space: nowrap;
        position: absolute;
        top: 1rem;
        right: -2rem;
        @include transform(rotate(45deg));

        a {
            display: block;
            font-weight: bold;
            font-size: 0.9rem;
            padding: 5px 40px;
            text-align: center;
        }
    }

    .themes {
        padding: $padding-default;

        .card-item {
            padding: 1rem 1rem 4rem;
        }

        .gpm-screenshot {
            text-align: center;
            img {
                margin: 0 auto;
                max-height: 300px;
            }
        }

        .gpm-name {
            margin-bottom: 0.5rem;
            white-space: inherit;
        }

        .gpm-actions {
            margin: 1rem -1rem -1rem -1rem;
            height: 4rem;
            text-align: center;
            padding: 1rem;
            font-size: 1rem;
            font-weight: bold;

            position: absolute;
            left: 0;
            right: 0;
            bottom: 1rem;
        }

        .active-theme .gpm-actions, &.inactive-theme .gpm-actions {
            line-height: 2rem;
        }

        .inactive-theme .gpm-actions {
            display: block;
            font-weight: normal;
        }
    }

    #gpm-release-toggle {
        float: right;
        margin-right: 3rem;
    }
}
