.pages-list {
    list-style: none;
    margin: 0;
    padding: 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
        padding: 0;

    }

    .row {
        @include transition(all 0.2s ease);
        line-height: 2.5rem;
        padding-right: 3rem;

        @include breakpoint(mobile-only) {
            padding-right: 1rem;
        }
        [data-hint]:after {
            line-height: 1 !important;
            width: auto !important;
            white-space: nowrap !important;
        }

        p.page-route {
            display: block;
            margin: 0;
            line-height: 1;
            font-size: 0.9rem;
            word-break: break-all;

            .spacer {
                display: inline-block;
                margin: 0 0.3rem;
            }
        }

        // fix for hint alignment
        .hint--bottom:before, .hint--bottom:after {
            left: 4px;
        }
        .hint:after, [data-hint]:after {
            border-radius: 2px;
        }

        .badge.lang {
            margin-left: 8px;
        }
    }

    .page-home {
        font-size: 1.4rem;
        margin-left: 10px;
        vertical-align: middle;
    }

    .page-info {
        font-size: 1.1rem;
        margin-left: 10px;
        vertical-align: middle;
    }

    .page-edit {
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
    }

    .page-item__row {
        display: flex;
    }

    .page-item__toggle {
        width: 25px;
        line-height: 1;
        padding-top: 7px;
    }

    .page-item__content {
        padding: 5px 0;
        width: calc(100% - 50px);
    }

    .page-item__content-name {
        line-height: 1;
    }

    .page-item__content-hint {
        line-height: 1.3;
        vertical-align: middle;
        max-width: 100%;
    }

    .page-item__tools {
        width: 90px;
        text-align: right;
        font-size: 1.4rem;

        .page-view {
            margin-right: 4px;
        }
    }

    .page-icon {

        color: #0082BA;
        font-weight: 700;

        &.children-open:before {
            content: '\f056';
        }

        &.children-closed:before{
            content: '\f055';
        }

        &.not-routable {
            color: #CE431D;
        }

        &.not-visible {
            color: #999;
        }

        &.modular {
            color: #9055AF;
        }
    }

    #pages-legend {
        margin-top: 1.5rem;
        text-align: center;

        h2, ul, li {
            display: inline-block;
        }

        li {
            margin-right: 0.75rem;
        }
    }
}

#page-filtering {
    margin: 0 $padding-default 1rem;
    @include clearfix;

    @include breakpoint(mobile-only) {
        margin: -1rem 1rem 1rem;
    }

    .page-filters {
        width: 60%;
        float: left;

        @include breakpoint(mobile-only) {
            width: 100%;
        }

    }

    .page-search {
        position: relative;
        width: 40%;
        float: left;
        padding-left: 2rem;
        text-indent: 2.5rem;
        &:after {
            position: absolute;
            right: 15px;
            top: 10px;
            content: '\f002';
            font-family: 'FontAwesome';
        }

        @include breakpoint(mobile-only) {
            width: 100%;
            padding-top: 1rem;
            padding-left: 0rem;

            &:after {
                top: 1.5rem;
            }
        }
    }

    .page-shortcuts {
        @include clearfix;
        clear: both;
        padding-top:5px;
    }

    .selectize-control.single.plugin-remove_button .selectize-input,
    .selectize-control.multi .selectize-input {

        padding: ($leading-margin / 4) ($leading-margin / 4);

        &.has-items {
            padding-top: 6px;
            padding-bottom: 4px;
        }

        input {
            font-size: $core-font-size;
            line-height: $core-line-height;
        }


        .item, .item.active {
            color: #777;
            background: #eee;

            padding: 2px 10px;
            &[data-value='Routable'] {
                background: #CE431D;
                color: $white;
            }
            &[data-value='NonRoutable'] {
                color: #CE431D;
            }
            &[data-value='Visible'] {
                background: #0082BA;
                color: $white;
            }
            &[data-value='NonVisible'] {
                color: #0082BA;
            }
            &[data-value='Modular'] {
                background: #9055AF;
                color: $white;
            }
            &[data-value='NonModular'] {
                color: #9055AF;
            }
            &[data-value='Published'] {
                background: #0093B8;
                color: $white;
            }
            &[data-value='NonPublished'] {
                color: #0093B8;
            }
        }

    }
}

.admin-form-wrapper {
    position: relative;
}

#admin-topbar {
    position: absolute;
    right: 5px;
    height: 50px;

    @include breakpoint(mobile-only) {
        position: relative;
        width: 100%;
        right: 0;
        padding: 0 .5rem;
        margin: -2rem 0 2rem 0;
    }

    #admin-mode-toggle, #admin-lang-toggle {
        @extend %vertical-align;
        height: 38px;
        display: inline-block;
        vertical-align: inherit;

        @include breakpoint(mobile-only) {
            float: right;
        }
    }

    #admin-lang-toggle {
        z-index: 3;
        float: left;
        margin-right: 5px;

        button {
            padding: 0.3rem 0.5rem;
            height: 100%;
        }

        .dropdown-menu {
            button {
                width: 100%;

            }
        }
    }

    .switch-grav {
        border: 0;
        line-height: 38px;

    }

    .switch-toggle {
        @include breakpoint(mobile-only) {
            width: 100%;
        }
    }
}

@for $i from 0 to 10 {
    .depth-#{$i} .row {
        padding-left: 3rem * ($i + 1);
    }

    @include breakpoint(mobile-only) {
        .depth-#{$i} .row {
            padding-left: 1rem * ($i + 1);
        }
    }
}

.selectize-route-option {
    display: flex;

    > :first-child {
        flex: 0 0 auto;
        width: auto;
        margin-right: 10px;
    }

    > :last-child {
        flex: 1 1 auto;

        > * {
            display: block;
        }
    }

}

.hidden {
    display: none!important;
}

.switch-toggle input[type="radio"] {
    display: none!important;
}
