#admin-login {
    @extend %vertical-align;
    max-width: 24rem;
    margin: 0 auto;

    &.wide {
        max-width: 50rem;

        h1 {
            height: 100px;

            &.custom-logo img {
                height: 80px;
            }
        }

        form {
            > .padding {
                padding: 3rem 2rem 8rem 2rem;
                > div {
                    width: 49%;
                    display: inline-block;

                    @include breakpoint(mobile-only) {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                .form-field {
                    padding: 0 1rem;
                }
            }

            label {
                padding: 0;
            }

            input {
                margin-bottom: 1rem;
                text-align: left;

                @include placeholder {
                    font-size: 1rem;
                    line-height: 2rem;
                }
            }

        }

        .grid {
            display: block;
        }

        .form-label, .form-data {
            display: block;
            width: 100%;
            @include flex(1);
        }
    }

    .form-field {
        padding-left: 0;
        margin-bottom: 0;
    }

    .form-label {
        display: none;
    }

    .form-data {
        padding-right: 0;
    }

    .wrapper-spacer {
        width: 100% !important;
        display: block !important;
        padding: 0 1rem;
        h3 {
            padding-left: 1rem;
        }
    }

    .instructions {
        display: block;
        padding: 2rem 3rem 0;
        margin: 0;
        font-size: 1.3rem;
        p {
            margin: 0;
        }
    }

    h1 {
        font-size: 0;
        color: transparent;
        height: 216px;
        margin: 0;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        &.text-logo {
            text-align: center;
            font-size: 4rem;
        }

        &.custom-logo {
            img {
                max-height: 160px;
            }
        }

        &.default-logo {
            svg {
                height: 320px;
            }
        }
    }

    h5 {
        margin-top: 0;
        text-align: center;
    }

    form {
        position: relative;

        .padding {
            padding: 3rem 3rem 6rem 3rem;
        }

        input {
            margin-bottom: 2rem;
            font-size: 1.4rem;
            line-height: 1.5;
            text-align: center;
            font-weight: 300;
            -webkit-font-smoothing: auto;
        }

        .form-actions {
            display: block !important;
            width: 100% !important;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1.5rem 0;

            .button {
                margin-bottom: 2px;
            }

            button:first-child {
                margin-right: 1rem;
            }
        }
    }

    .alert {
        text-align: center;
        padding: 1rem 3rem;
    }
}

.twofa-secret-code {
      font-weight: bold;
}

.twofa-wrapper {
    margin-top: 5px;
}


.twofa-secret {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 600ms, visibility 600ms;

    &.show {
        position: static;
        visibility: visible;
        opacity: 1;
    }

}
