#phpinfo {
    img { display: none;}

    table {
        margin: 1rem 0 0;
    }
    
    tr {
        &:hover {
            background: transparent;
        }
    }

    td {
        word-wrap: break-word;
	    overflow: hidden;

        h1 {
            margin: 0rem (- $padding-default) 0rem !important;
        }
    }

    hr {
        border-bottom: 0;
    }

    h1 {
        font-size: 2.3rem;
    }

    h2 {
        font-size: 1.7rem;
        margin: 3rem ($padding-default) 0rem !important;
    }
}
