// Grav Editor
.grav-editor-content {
    position: relative;
}

.grav-editor {
    [data-grav-editor] {
        height: 300px;

        &.code-mirrored {
            padding: 0;
            border: 0;
            position: absolute;
            z-index: -1;
            display: block !important;
        }
    }

    .CodeMirror {
        .CodeMirror-vscrollbar, .CodeMirror-hscrollbar {
            z-index: 1;
        }
    }

    .CodeMirror-scroll {
        //margin-right: -36px;
        position: static;

        // hide native scrollbar at the bottom
        padding-bottom: 15px;
    }

    .grav-editor-resizer {
        height: 6px;
        cursor: row-resize;
        border-radius: 0 0 3px 3px;
        margin-top: -2px;
        position: relative;
        z-index: 1;
    }
    .CodeMirror-selected {
        background: #D6E8FF;
    }

    .CodeMirror-gutters {
        height: 100%!important;
    }
}

.grav-editor-fullscreen {
    position: fixed;
    top: 4.2rem;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;

    .grav-editor-content {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;

        overflow-y: auto;
    }

    .grav-editor-content, .CodeMirror-wrap, .grav-editor-preview {
        height: 100% !important;
    }

    .grav-editor-toolbar {
        flex: 0 0 auto;
    }

    .grav-editor-toolbar, .grav-editor-toolbar ul li:first-child a, .grav-editor-toolbar-flip ul li:last-child a {
        border-radius: 0 !important;
    }

    .grav-editor-resizer {
        display: none;
    }

    .grav-editor-content.is-active {
        display: flex;
    }
}

.grav-editor-toolbar {
    @include align-items(stretch);
    @include display(flex);
    @include flex-direction(row);

    .grav-editor-actions {
        @include flex(1);
    }
    .grav-editor-modes {
        flex-shrink: 0;
        margin-right: 2px;
        ul {
            float: right;
        }
    }
}

.grav-editor-toolbar {
    @include clearfix;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            &:first-child a {
                border-top-left-radius: 4px;
            }
            display: inline-block;
        }

        a {
            display: inline-block;
            cursor: pointer;
            background-color: transparent;
            border-radius: 0;
            padding: 0.4rem;
            margin-top: -1px;
            border: 1px solid transparent;

            &, &:hover, &:active, &:focus {
                outline: 0;
            }
        }
    }

    .dropdown-menu li {
        width: 50%;
    }
}

.grav-editor-hide-toolbar {
    display: none;
}

.grav-editor-toolbar .grav-editor-modes {
    li {
        &:first-child a {
            border-top-left-radius: 0;
        }
        &:last-child a {
            border-top-right-radius: 4px;
        }
    }

    a {
        &:hover {
            /*border: 1px solid transparent;
            border-bottom: 1px solid darken($content-bg, 20%);*/
        }
    }
}

.grav-editor-toolbar-flip {
    float: right;
    ul li:last-child a {
        border-top-right-radius: 4px;
    }
}

.grav-editor-content, .grav-editor-preview {
    @include clearfix;
    cursor: text;
    display: none;

    &.is-active {
        display: block;
    }
}

.grav-editor-content {
    /* PADDING */
    .CodeMirror-lines {
        padding: 10px 0; /* Vertical padding around content */
    }
}

#admin-main {
    .grav-editor-preview {
        padding: 20px;
        overflow-y: scroll;
        position: relative;
        background: #fff;
        line-height: 1.5;

        h1, h2, h3, h4, h5, h6 {
            padding: 0;
            margin: 2rem 0 1rem;
            border-bottom: 0 !important;
        }

        p {
            padding: 0;
            margin: 0.5rem 0 1rem;
        }

        pre {
            padding: 1rem;
        }

    }
}


.grav-editor-toolbar {
    [data-hint]:after {
        white-space: nowrap !important;
        max-width: 300px;
        width: auto !important;
    }

    p {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 20px;
    }
}

// CodeMirror Theme
.cm-s-paper {
    $paper-fg: #666;
    $tag: darken($paper-fg, 10%);

    &.CodeMirror {
        color: $paper-fg;
        line-height: 1.4;
        font-size: 16px;
    }

    .cm-link {color: #0082ba;}
    .cm-comment {color: lighten($paper-fg,10%);}
    .cm-header {color: darken($paper-fg,5%);}
    .cm-strong {color: darken($paper-fg,10%);}
    .cm-em {color: darken($paper-fg, 10%);}
    .cm-string {color: #0082BA;}
    .cm-tag {color: $tag}
    .cm-bracket {color: lighten($tag, 10%);}
    .cm-hr {color: lighten($paper-fg,40%);font-weight: bold;}

    .cm-keyword {color: #0082ba;}
    .cm-atom {color: #9055af;}
    .cm-meta {color: $paper-fg;}

    .cm-number {color: #7f8c8d;}
    .cm-def {color: #00f;}
    .cm-variable {color: black;}
    //.cm-variable-2 {color: #555;}
    //.cm-variable-3 {color: #085;}
    .cm-property {color: black;}
    .cm-operator {color: black;}


    .cm-string-2 {color: #f50;}
    .cm-meta {color: #555;}
    .cm-error {color: #f00;}
    .cm-qualifier {color: #555;}
    .cm-builtin {color: #555;}


    .cm-attribute {color: #7f8c8d;}

    .cm-quote {color: #888;}


    .cm-header-1 {font-size: 140%;}
    .cm-header-2 {font-size: 120%;}
    .cm-header-3 {font-size: 110%;}

    .cm-negative {color: #d44;}
    .cm-positive {color: #292;}
    .cm-header, .cm-strong {font-weight: bold;}
    .cm-em {font-style: italic;}
    .cm-link {text-decoration: underline;}

    .cm-invalidchar {color: #f00;}
}
