.default-animation {
    @include transition(all 0.5s ease);
}

// Pulse Animation
.pulse {
	animation-name: pulse_animation;
	animation-duration: 2000ms;
	transform-origin:70% 70%;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes pulse_animation {
	0% { transform: scale(1); }
	30% { transform: scale(1); }
	40% { transform: scale(1.08); }
	50% { transform: scale(1); }
	60% { transform: scale(1); }
	70% { transform: scale(1.05); }
	80% { transform: scale(1); }
	100% { transform: scale(1); }
}

.default-border-radius {
    border-radius: 4px;
}

.default-glow-shadow {
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.default-box-shadow {
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.03);
}

.login-box-shadow {
	box-shadow: 0 10px 100px 0px rgba(0,0,0,0.4);
}

.padding-horiz {
	padding-left: $padding-horiz;
	padding-right: $padding-horiz;
	@include breakpoint(desktop-only) {
		padding-left: $padding-horiz - 3rem;
		padding-right: $padding-horiz - 3rem;
	}

	@include breakpoint(mobile-only) {
		padding-left: $padding-horiz - 6rem;
		padding-right: $padding-horiz - 6rem;
	}
}

.padding-vert {
	padding-top: $padding-vert;
	padding-bottom: $padding-vert;
}
