// Sizes
$header-height: 5rem;
$footer-height: 6rem;
$border-radius: 4px;

// Font Weights
$font-weight-bold: 600;
$font-weight-regular: 400;
$font-weight-light:300;

// Global Paddings
$padding-default: 1.5rem;
$padding-horiz: 7rem;
$padding-vert: 3rem;

//Forms
$form-label-width: 30%;
$form-border-radius: 4px;
$form-border-width: 1px;
$form-label-pad: 5px 0 5px 3rem;
$form-input-pad: 5px 10px;
$form-select-pad: 5px 30px 5px 10px;
