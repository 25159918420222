.button {
	@extend %button;
}

.button-group {
    position: relative;
    display: inline-block;
    vertical-align: top;

    // real button
    > .button:first-child:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    > .button:first-child {
        margin-left: 0 !important;
    }

    > .button {
    }

    // toggle caret
    > .button + .dropdown-toggle {
        text-align: center;
        padding-right: 8px;
        padding-left: 8px;

        i {
            margin: 0;
        }
    }

    > .button:last-child:not(:first-child), > .dropdown-toggle:not(:first-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .button + .button, .button + .button-group, .button-group + .button, .button-group + .button-group {
        margin-left: -3px;
    }

    &:last-child {
        .dropdown-menu {
            left: inherit;
            right: 0;
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 150px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border-radius: 4px;

        .button {
            margin-right: 0 !important;
        }

        &.language-switcher {
            min-width: 50px;
        }

        &.lang-switcher {
            min-width: 150px;
            left: inherit;
            right: 0;

            button {
                width: 100%;
            }
        }

        .divider {
            height: 1px;
            margin: 9px 0;
            overflow: hidden;
        }

        li > a {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: 400;
            line-height: 1.42857143;

            &:focus, &:hover {
                text-decoration: none;
            }
        }
    }
}

.open > .dropdown-menu {
    display: block;
}

.dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
}

// Override style for stubborn gumroad button
#admin-main .admin-block a.gumroad-button {
    padding: 0.3rem 1.5rem !important;
    font-size: inherit !important;
    font-weight: 400 !important;
    line-height: inherit !important;
    font-family: inherit !important;
    border-radius: 4px !important;
    box-shadow: inherit !important;
    .gumroad-button-logo {
        display: none !important;
        background-image: none !important;
    }
}
