// Body Base
body {
	font-weight: 400;
    @include breakpoint(mobile-only) {
        font-size: 0.8rem;
    }
}

// Headings
h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
	text-rendering: optimizeLegibility;
	letter-spacing: -0px;
}

h1 {
	font-size: $h1-font-size;
    @include breakpoint(mobile-only) {
        font-size: 2.5rem;
        line-height: 1.2;
        margin-bottom: 2.5rem;
    }
}

h2 {
	@include breakpoint(tablet-range) {
		font-size: $h2-font-size - .40;
	}
	@include breakpoint(mobile-only) {
		font-size: $h2-font-size - .50;
	}
}

h3 {
	@include breakpoint(tablet-range) {
		font-size: $h3-font-size - .40;
	}
	@include breakpoint(mobile-only) {
		font-size: $h3-font-size - .50;
	}
}

h4 {
	@include breakpoint(tablet-range) {
		font-size: $h4-font-size - .40;
	}
	@include breakpoint(mobile-only) {
		font-size: $h4-font-size - .50;
	}
}

h1 {
	letter-spacing: -3px;
}

h2 {
	letter-spacing: -2px;
}

h3 {
	letter-spacing: -1px;
}

// Icon Tweaks
i.fa {
	&.fa-heart, &.fa-heart-o {
		&.pulse {
			color: #920
		}
	}
}

pre {
	padding: 2rem;
	border-radius: 3px;
	code {
		background: inherit;
	}
}

// Label
.label {
    vertical-align: middle;
    border-radius: 100%;
    height: 1rem;
    min-width: 1rem;
    line-height: 1rem;
    display: inline-block;
    text-align: center;
    font-size: $core-font-size - 0.3rem;
    margin-right: 0.75rem;
}

// Display RESET
form {
    .form-display-wrapper {
        h1 {
           padding: 0 !important;
           font-size: 1.7rem !important;
            border-bottom-width: 2px !important;
        }

        h2 {
            padding: 0 !important;
            font-size: 1.5rem !important;
            border-bottom-width: 1px !important;
        }

        h3 {
            padding: 0 !important;
            font-size: 1.3rem !important;
            border-bottom-width: 1px !important;
        }

        h4 {
            padding: 0 !important;
            font-size: 1.1rem !important;
        }

        blockquote {
            padding: 15px;
            p {
                font-size: 0.9rem;
                color: inherit;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        code, pre {
            font-size: 0.8rem;
        }

        table {
            border-collapse: collapse;
            width: 100%;

            th {
                padding: 10px !important;
                &:last-child {
                    border-right: none;
                }
            }

            td {
                padding: 10px !important;
                &:last-child {
                    border-right: none;
                }
            }

            tr {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

}

