// +-------------------------------------------------------------------------+
// | Flat Colors                                                                 |
// +-------------------------------------------------------------------------+

// Greens
$color-navy: #001f3f;
$color-blue: #0074D9;
$color-aqua: #7FDBFF;
$color-teal: #39CCCC;
$color-olive: #3D9970;
$color-green: #2ECC40;
$color-lime: #01FF70;
$color-yellow: #FFDC00;
$color-orange: #FF851B;
$color-red: #FF4136;
$color-maroon: #85144b;
$color-fuchsia: #F012BE;
$color-purple: #B10DC9;

