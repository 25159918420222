/* Media */
$dark-text: #333;

.pages-list-container {
    width: 30%;
    flex: none!important;
    padding: 0!important;

    .pages-list {
        width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.depth-0 .row {
            padding-left: .5rem;
        }

        .depth-1 .row {
            padding-left: 1rem;
        }

        .page-item {
            .page-link {
                cursor: pointer;
            }
        }

        .row.active {
            background-color: rgba(#00a6cf, 0.1);
        }
    }
}

.media-page-dropzone {
    width: 100%;
    margin-bottom: 20px;
}

.media-container {
    &.size-2-3 {
        flex: none!important;
        width: 70%;
    }


    .filters {
        padding-bottom: 30px;
        width: 100%;


        .filter-wrapper {
            margin-left: 10px;
            margin-right: 10px;

            &:after {
                margin-top: -25px;
                border: 0px none;
                content: "\f078";
                font-family: "FontAwesome";
                right: 12px;
                top: 50%;
                line-height: 0;
                color: #9BA2A6;
                pointer-events: none;
                float: right;
                padding-right: 10px;
            }
        }

        .filter {
            option {
                padding: 8px;
            }
        }
    }

    .files {
        padding-left: 30px;
        padding-right: 30px;

    }

    .admin-media-title {

        color: $dark-text;
        margin: 0px;
        font-size: .8rem;
        line-height: 1.3;

    }

    .card-item {
        width: 49%;

        &.empty-space {

            background: rgba(255,255,255,0.4);
            border: 1px solid rgba(0,0,0,0.1) !important;

            color: rgba(0,0,0,0.3);

            max-width: none;
            display: block;
            text-align: center;

            top: 20%;
            height: 10rem !important;
            width: 50% !important;
            margin: 0 auto !important;
            font-size: 2rem;
            line-height: 8rem;
            transform: translateY(-50%);


        }
    }

    .media-details {
        text-align: center;
        img {
            width: 50%;
        }
        h2 {
            margin: 0;
            font-size: 1.8rem;
        }
        h5 {
            color: #9BA2A6;
            font-size: 1.1rem;
            margin: 0;
        }
    }


    h1 {
        padding: 0 2rem 0.5rem!important;
    }

    .admin-form-wrapper {
        width: 100%;
        padding-bottom: 20px;
    }
}

.spinning-wheel {
    text-align: center;
}

/* Media in-page modal */

.media-container.in-modal {
    h1 {
        font-size: 30px!important;
        margin-top: 0px;
    }

    a {
        color: #333;
    }

    .pages-list li {
        list-style-type: none;
        margin-left: 0;
    }
}

//Mediapicker field
.remodal.remodal-mediapicker {
  max-width: 70vw;
  padding: 0;
}

.media-container {
  height: 70vh;


  .grid {
    justify-content: flex-start;
  }


  &.in-modal {

      .gm-scrollbar {
          background: rgba(255,255,255,0.2);

          .thumb {
              background-color: rgba(0,0,0,0.2) !important;
              &:hover, &.active {
                  background-color: rgba(0,0,0,0.3) !important;
              }
          }
      }
  }

  h5 {
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 15px;
    margin: 0;
    height: 55px;
  }

  .filter-wrapper {
    float: right;
    margin-right: 5px;
    margin-top: 10px;
  }

  .filter-date {
    width: 150px;

  }

  .filter-type {
    width: 100px;
    margin-right: 15px;
  }

  .pages-list-container {
    position: relative;

    .pages-list {
      border-top: 0;
      padding-right: 10px;
      font-size: 90%;

      .row {
        transition: none !important;
        line-height: 2rem;
      }

      @for $i from 1 to 10 {
        .depth-#{$i} .row {
          padding-left: 1rem * ($i + 1);
        }

      }

    }

    .mediapicker-scroll {
      position: absolute;
      top: 55px;
      bottom: 3px;
      height: inherit;
    }
  }

  .thumbs-list-container {
    position: relative;

    .media-range {
        position: absolute;
        right: 15px;
        bottom: 5px;
    }

    .mediapicker-scroll {
      position: absolute;
      top: 55px;
      bottom: 30px;
      height: inherit;
    }

      .gm-scrollbar.-horizontal {
          display: none;
      }

  }


  #admin-media {

    padding: 15px;
    height: 100%;

    align-items: flex-start;
    align-content: flex-start;

    .card-item {
      width: 100px;
      padding: 2px;
      margin: 0 5px 5px 0;
      border: 0;

      img {
        display: block;
      }
    }

    .admin-media-details {
      position: relative;

      .admin-media-title {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: rgba(0,0,0,0.3);
        color: #fff;
        font-size: 10px;
        overflow: hidden;
        line-height: 2;
        text-indent: 2px;
        white-space: nowrap;
      }
    }
  }
}

[data-remodal-id="metadata"] {
    h1 > * {
        vertical-align: middle;
    }
    h1 strong {
        @include ellipsis(75%);
    }
    .metadata-preview {
        display: flex;
        margin: 0 3rem;

        .meta-preview {
            max-width: 200px;
        }

        .meta-content ul {
            margin: 0;
        }
    }
}
