// Core
$white:							#ffffff;
$black:							#000000;
$light-gray:					#dddddd;

$core-border-color:				$light-gray;
$core-border-color-hover: 		darken($core-border-color, 10);

$code-text:						#c7254e;
$code-bg:						#f9f2f4;
$pre-text:						#237794;
$pre-bg:						#f6f6f6;

// Shadows
$core-box-shadow: 				inset 0 1px 3px hsla(0, 0%, 0%, 0.06);
